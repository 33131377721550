import { useEffect, useState } from "react";
import { Link } from "react-scroll";
import { HashLink } from "react-router-hash-link";

export default function Header({ homepage }) {
  const [menuOpen, setMenuOpen] = useState(false);
  const [subOne, setSubOne] = useState(false);
  const [subTwo, setSubTwo] = useState(false);
  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    const checkScroll = () => {
      const isScrolled = window.scrollY > 100;
      setScroll(isScrolled);
    };
    checkScroll();
    window.addEventListener("scroll", checkScroll);
    return () => {
      window.removeEventListener("scroll", checkScroll);
    };
  }, []);
  return (
    <>
      <header className={scroll ? "blurHeader" : ""}>
        <div className="row header">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-2 p-0 d-flex justify-content-between align-items-center flex-row-reverse">
            <div
              className="barsMenu"
              onClick={() => {
                setMenuOpen(true);
              }}
              style={{
                textAlign: "right",
              }}
            >
              <i
                className="fas fa-bars"
                style={{
                  fontSize: 20,
                }}
              ></i>
            </div>
            <a href="/">
              <img
                alt="Emorya"
                src={require("../assets/logo.png")}
                className="logoMenu"
              />
            </a>
          </div>
          <div className="col-sm-10 menu deskmenu">
            <ul>
              <li>
                {homepage ? (
                  <>
                    <Link
                      to="token-sale"
                      smooth={true}
                      onClick={() => setMenuOpen(false)}
                    >
                      <a href="#token-sale" onClick={() => setMenuOpen(false)}>
                        Token Sale
                      </a>
                    </Link>
                  </>
                ) : (
                  <>
                    <HashLink smooth to="/#token-sale">
                      Token Sale
                    </HashLink>
                  </>
                )}
              </li>
              <li>
                {homepage ? (
                  <>
                    <Link to="road-map" smooth={true}>
                      <a href="#road-map" onClick={() => setMenuOpen(false)}>
                        Road Map
                      </a>
                    </Link>
                  </>
                ) : (
                  <>
                    <HashLink
                      smooth
                      to="/#road-map"
                      onClick={() => setMenuOpen(false)}
                    >
                      Road Map
                    </HashLink>
                  </>
                )}
              </li>
              <li
                style={{
                  display: "none",
                }}
              >
                <a
                  href="/#/early-access-whitelist"
                  onClick={() => setMenuOpen(false)}
                >
                  Apps
                </a>
              </li>
              <li>
                {homepage ? (
                  <>
                    <Link to="team-section" smooth={true}>
                      <a
                        href="#team-section"
                        onClick={() => setMenuOpen(false)}
                      >
                        Team
                      </a>
                    </Link>
                  </>
                ) : (
                  <>
                    <HashLink
                      smooth
                      to="/#team-section"
                      onClick={() => setMenuOpen(false)}
                    >
                      Team
                    </HashLink>
                  </>
                )}
              </li>
             

              <li>
                {homepage ? (
                  <>
                    <Link to="contact" smooth={true}>
                      <a href="#contact" onClick={() => setMenuOpen(false)}>
                        Contact
                      </a>
                    </Link>
                  </>
                ) : (
                  <>
                    <HashLink
                      smooth
                      to="/#contact"
                      onClick={() => setMenuOpen(false)}
                    >
                      Contact
                    </HashLink>
                  </>
                )}
              </li>
              <li>
                <a href="#/" onClick={() => setMenuOpen(false)}>
                  Community &nbsp;<i className="fas fa-caret-down"></i>
                </a>
                <div className="submenu">
                  <a
                    href="https://twitter.com/EmoryaFinance"
                    target="_blank"
                    rel="noreferrer"
                    onClick={() => setMenuOpen(false)}
                  >
                    Twitter
                  </a>
                  <a
                    href="https://facebook.com/emoryafinance"
                    target="_blank"
                    rel="noreferrer"
                    onClick={() => setMenuOpen(false)}
                  >
                    Facebook
                  </a>
                  <a
                    href="https://discord.gg/9Jrj7U9Y9R"
                    target="_blank"
                    rel="noreferrer"
                    onClick={() => setMenuOpen(false)}
                  >
                    Discord
                  </a>
                  <a
                    href="https://linkedin.com/company/emorya-finance"
                    target="_blank"
                    rel="noreferrer"
                    onClick={() => setMenuOpen(false)}
                  >
                    Linkedin
                  </a>
                  <a
                    href="https://t.me/EmoryaFinanceInternational"
                    target="_blank"
                    rel="noreferrer"
                    onClick={() => setMenuOpen(false)}
                  >
                    Telegram International
                  </a>
                  <a
                    href="https://t.me/EmoryaFinanceRO"
                    target="_blank"
                    rel="noreferrer"
                    onClick={() => setMenuOpen(false)}
                  >
                    Telegram Romania
                  </a>
                </div>
              </li>
              <li>
                <a href="#/" onClick={() => setMenuOpen(false)}>
                  Docs&nbsp;<i className="fas fa-caret-down"></i>
                </a>
                <div
                  className="submenu"
                  style={{
                    width: "300px",
                  }}
                >
                  <a
                    href="https://docs.emorya.com/docs/whitepaper/introduction/"
                    target="_blank"
                    rel="noreferrer"
                    onClick={() => setMenuOpen(false)}
                  >
                    Whitepaper
                  </a>

                  <a
                    href="https://docs.emorya.com/docs/nft/introduction/"
                    target="_blank"
                    rel="noreferrer"
                    onClick={() => setMenuOpen(false)}
                  >
                    NFT Whitepaper
                  </a>
                  <a href="/#/terms-of-use" onClick={() => setMenuOpen(false)}>
                    Terms of Use
                  </a>
                  <a href="/#/token-release" onClick={() => setMenuOpen(false)}>
                    Token Release
                  </a>
                </div>
              </li>

              <li
                style={{
                  paddingLeft: 20,
                }}
              >
                <div
                  className="btn-green-wrapper" style={{
                    borderRadius:5
                  }}
                  onClick={() => {
                    window.open("https://emorya.com/#/partner/", "_blank");
                    setMenuOpen(false);
                  }}
                >
                  <button className="btn-green" style={{
                    fontSize:11,
                    padding:"5px 10px",
                    borderRadius:5
                  }}>Become Partner</button>
                </div>
              </li>

              <li
                style={{
                  paddingLeft: 10,
                }}
              >
                <div
                  className="btn-green-wrapper" style={{
                    borderRadius:5
                  }}
                  onClick={() => {
                    window.open(
                      "https://app.emorya.com/?_ga=2.74025434.916851220.1689183700-2139228314.1684962772",
                      "_blank"
                    );
                    setMenuOpen(false);
                  }}
                >
                  <button className="btn-green" style={{
                    fontSize:11,
                     padding:"5px 10px",
                     borderRadius:5
                  }}>Xportal Connect</button>
                </div>

              
              </li>

              <li
                style={{
                  paddingLeft: 10,
                }}
              >

              <div
                  className="btn-green-wrapper" style={{
                    borderRadius:5,
                  }}
                  onClick={() => {
                    window.open(
                      "https://emorya.com/#/landing/",
                      "_blank"
                    );
                    setMenuOpen(false);
                  }}
                >
                  <button className="btn-green" style={{
                    fontSize:11,
                    padding:"5px 10px",
                    borderRadius:5
                  }}>
                    Emorya App</button>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </header>
      <div
        className="mobileHeader"
        style={{
          display: menuOpen ? "block" : "none",
        }}
      >
        <div className="row header">
          <div className="col-sm-12 d-flex flex-row-reverse justify-content-between align-items-center p-0">
            <div
              onClick={() => {
                setMenuOpen(false);
              }}
              style={{
                textAlign: "right",
                float: "right",
              }}
            >
              <i
                className="fas fa-times"
                style={{
                  fontSize: 20,
                }}
              ></i>
            </div>
            <a href="/">
              <img
                alt="Emorya"
                src={require("../assets/logo.png")}
                style={{
                  width: 200,
                }}
              />
            </a>
          </div>
          <div className="col-sm-12 menu">
            <ul>
              <li>
                {homepage ? (
                  <>
                    <Link to="token-sale" smooth={true}>
                      <a href="#token-sale" onClick={() => setMenuOpen(false)}>
                        Token Sale
                      </a>
                    </Link>
                  </>
                ) : (
                  <>
                    <HashLink
                      to="/#token-sale"
                      onClick={() => setMenuOpen(false)}
                    >
                      Token Sale
                    </HashLink>
                  </>
                )}
              </li>
              <li>
                {homepage ? (
                  <>
                    <Link to="road-map" smooth={true}>
                      <a href="#road-map" onClick={() => setMenuOpen(false)}>
                        Road Map
                      </a>
                    </Link>
                  </>
                ) : (
                  <>
                    <HashLink
                      to="/#road-map"
                      onClick={() => setMenuOpen(false)}
                    >
                      Road Map
                    </HashLink>
                  </>
                )}
              </li>
              <li
                style={{
                  display: "none",
                }}
              >
                <a
                  href="/#/early-access-whitelist"
                  onClick={() => setMenuOpen(false)}
                >
                  Apps
                </a>
              </li>
              <li>
                {homepage ? (
                  <>
                    <Link to="team-section" smooth={true}>
                      <a
                        href="#team-section"
                        onClick={() => setMenuOpen(false)}
                      >
                        Team
                      </a>
                    </Link>
                  </>
                ) : (
                  <>
                    <HashLink
                      to="/#team-section"
                      onClick={() => setMenuOpen(false)}
                    >
                      Team
                    </HashLink>
                  </>
                )}
              </li>
              <li>
                <a
                  href="https://docs.emorya.com/docs/whitepaper/introduction/?_ga=2.71224792.916851220.1689183700-2139228314.1684962772"
                  onClick={() => setMenuOpen(false)}
                >
                  White Paper
                </a>
              </li>

              <li>
                {homepage ? (
                  <>
                    <Link to="contact" smooth={true}>
                      <a href="#contact" onClick={() => setMenuOpen(false)}>
                        Contact
                      </a>
                    </Link>
                  </>
                ) : (
                  <>
                    <HashLink to="/#contact" onClick={() => setMenuOpen(false)}>
                      Contact
                    </HashLink>
                  </>
                )}
              </li>
              <li>
                <span
                  className="a"
                  href="#/"
                  onClick={() => {
                    setSubOne(!subOne);
                    setSubTwo(false);
                  }}
                >
                  Community &nbsp;<i className="fas fa-caret-down"></i>
                </span>
                <div
                  className="submenu"
                  style={{
                    display: subOne ? "block" : "none",
                  }}
                >
                  <a
                    href="https://twitter.com/EmoryaFinance"
                    target="_blank"
                    rel="noreferrer"
                    onClick={() => setMenuOpen(false)}
                  >
                    Twitter
                  </a>
                  <a
                    href="https://facebook.com/emoryafinance"
                    target="_blank"
                    rel="noreferrer"
                    onClick={() => setMenuOpen(false)}
                  >
                    Facebook
                  </a>
                  <a
                    href="https://discord.gg/9Jrj7U9Y9R"
                    target="_blank"
                    rel="noreferrer"
                    onClick={() => setMenuOpen(false)}
                  >
                    Discord
                  </a>
                  <a
                    href="https://linkedin.com/company/emorya-finance"
                    target="_blank"
                    rel="noreferrer"
                    onClick={() => setMenuOpen(false)}
                  >
                    Linkedin
                  </a>
                  <a
                    href="https://t.me/EmoryaFinanceInternational"
                    target="_blank"
                    rel="noreferrer"
                    onClick={() => setMenuOpen(false)}
                  >
                    Telegram International
                  </a>
                  <a
                    href="https://t.me/EmoryaFinanceRO"
                    target="_blank"
                    rel="noreferrer"
                    onClick={() => setMenuOpen(false)}
                  >
                    Telegram Romania
                  </a>
                </div>
              </li>
              <li>
                <span
                  className="a"
                  onClick={() => {
                    setSubOne(false);
                    setSubTwo(!subTwo);
                  }}
                >
                  Docs&nbsp;&nbsp;<i className="fas fa-caret-down"></i>
                </span>
                <div
                  className="submenu"
                  style={{
                    display: subTwo ? "block" : "none",
                  }}
                >
                  <a
                    href="https://docs.emorya.com/docs/nft/introduction/"
                    target="_blank"
                    rel="noreferrer"
                    onClick={() => setMenuOpen(false)}
                  >
                    NFT Whitepaper
                  </a>
                  <a href="/#/terms-of-use" onClick={() => setMenuOpen(false)}>
                    Terms of Use
                  </a>
                  <a href="/#/token-release" onClick={() => setMenuOpen(false)}>
                    Token Release
                  </a>
                </div>
              </li>

              <li
                style={{
                  marginTop: 20,
                }}
              >
                <div
                  className="btn-green-wrapper"
                  onClick={() => {
                    window.open("https://emorya.com/#/partner/", "_blank");
                    setMenuOpen(false);
                  }}
                >
                  <button className="btn-green">Become Partner</button>
                </div>
              </li>
              <li
                style={{
                  marginTop: 20,
                }}
              >
                <div
                  className="btn-green-wrapper"
                  onClick={() => {
                    window.open(
                      "https://app.emorya.com/?_ga=2.74025434.916851220.1689183700-2139228314.1684962772",
                      "_blank"
                    );
                    setMenuOpen(false);
                  }}
                >
                  <button className="btn-green">Xportal Connect</button>
                </div>
              </li>
              <li
                style={{
                  marginTop: 20,
                }}
              >
              <div
                  className="btn-green-wrapper" style={{
          
                  }}
                  onClick={() => {
                    window.open(
                      "https://emorya.com/#/landing/",
                      "_blank"
                    );
                    setMenuOpen(false);
                  }}
                >
                  <button className="btn-green" style={{
                  
                  }}>
                    Emorya App</button>
                </div>
                </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}
